<template>
  <div label="Ads">
    <div class="">
      <AdsSheetsHandler :chosenFilters="filters" />
      <!-- ************************************************************************************ -->
      <!-- Filters and add new ad button-->
      <!-- ************************************************************************************ -->
      <div>
        <MetricFilterBox
          :defaultFilterData="metrics.states"
          v-model="metricsList"
          @clearFilters="clearFilters"
          @openMetric="saveMetircs"
          :clearBtnShowen="metricsList.length > 0"
        >
          <metricbox
            v-for="metric in metricsList"
            :key="metric.key"
            :label="$t(metric.label)"
            @close="closeMetric(metric, metric.filterKey)"
          >
            <template slot="filterBody">
              <component
                v-if="metric.key == 'isActive'"
                :is="metric.compName"
                v-model="filters[metric.key]"
                :state="
                  metric.displayedData ? metric.displayedData : filters.state
                "
                @change="filtersChanged(metric.key, $event)"
                @enterClicked="
                  filterEnterClicked(metric.key, filters[metric.key])
                "
                :meta="metric.meta ? metric.meta : null"
              ></component>
              <component
                :is="metric.compName"
                v-model="filters[metric.key]"
                :meta="metric.meta ? metric.meta : null"
                @change="filtersChanged(metric.key, $event)"
              ></component>
            </template>
          </metricbox>
        </MetricFilterBox>
        <el-row
          :gutter="20"
          type="flex"
          justify="end"
          class="u-bottom-margin--2x"
        >
          <el-col :span="2.5">
            <el-button
              class="u-bottom-margin--1x"
              type="primary"
              @click="createAd"
              >{{ $t("adsPageCreateNewAdButtonLabel") }}</el-button
            >
          </el-col>
        </el-row>
      </div>
    </div>
    <el-table
      :data="adsList"
      border
      stripe
      @select="setSelectedAds"
      @select-all="setSelectedAds"
    >
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column
        prop="id"
        :label="$t('adsPageTableColumnId')"
        width="100"
      >
      </el-table-column>
      <el-table-column prop="url" label="Image Url" width="200">
      </el-table-column>
      <el-table-column
        prop="image"
        :label="$t('adsPageTableColumnImage')"
        width="200"
      >
        <template slot-scope="props">
          <!-- <div class="u-right-margin--2x">
            <img
              width="100"
              height="100"
              style="object-fit: scale-down"
              :src="props.row.uri"
            /> 
            </div>-->

          <EnlargeableImage
            :src="props.row.url"
            :src_large="props.row.url"
            animation_duration="0"
            trigger="hover"
          />
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="provider"
        :label="$t('adsPageTableColumnProvider')"
        width="200"
      >
      </el-table-column> -->

      <el-table-column prop="type" :label="$t('adsPageTableColumnType')">
      </el-table-column>
      <el-table-column prop="name" :label="$t('adsPageTableColumnName')">
        <template slot-scope="props">
          <div>
            {{ props.row.name ? props.row.name : "null" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="opertaions"
        :label="$t('adsPageTableColumnOperations')"
      >
        <template slot-scope="props">
          <div class="operations-column-buttons-holder">
            <el-button
              v-if="!checkAdDate(props.row.endDate)"
              type="danger"
              size="mini"
              @click="deleteAd(props.row)"
              >{{ $t("adsPageTableDeleteAdButton") }}</el-button
            >
            <el-button type="success" size="mini" @click="viewAd(props.row)">{{
              $t("adsPageTableViewAdDetailsButton")
            }}</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="pagination.totalResultsCount"
        :current-page="paginationCurrentPage"
        :page-size="25"
        @current-change="handlePagination"
      ></el-pagination>
    </div>
    <el-collapse-transition>
      <div class="bulk-action-button-holder" v-show="selectedAds.length > 0">
        <div class="bulk-action-button-holder__parent">
          <el-button
            type="danger"
            size="default"
            @click="bulkDeleteAdsClicked"
            >{{ $t("adsPageTableBulkDeleteAdsButton") }}</el-button
          >
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import moment from "moment";
import { remove, isEmpty } from "lodash";

import { MarktingService } from "../../../services/markting.service";
import { mapGetters } from "vuex";
import dropdownCategoriesSelect from "../../../components/dropdownCategoriesSelect";
import EnlargeableImage from "@diracleo/vue-enlargeable-image";
import AdsSheetsHandler from "./AdsSheetsHandler";
import metricbox from "@/components/transaction/metricbox";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import { adsMetrics } from "@/components/transaction/metricboxComponents";
export default {
  components: {
    dropdownCategoriesSelect,
    EnlargeableImage,
    AdsSheetsHandler,
    MetricFilterBox,
    metricbox,
    ...adsMetrics,
  },
  data() {
    return {
      adsList: [],
      filters: {},
      metricsList: [],
      metrics: {
        states: [
          {
            key: "section",
            label: "Section",
            compName: "providerTypeMetric",
            meta: {
              multiple: false,
            },
          },
          {
            key: "regions",
            label: "Regions",
            compName: "regionMetric",
          },
          {
            key: "areas",
            label: "Areas",
            compName: "areaMetric",
          },
          {
            key: "name",
            label: "Ad Name",
            compName: "stringMetric",
            meta: {
              placeholder: "Input Ad name",
            },
          },
          {
            key: "createdAt",
            label: "Creation Date",
            compName: "startDateMetric",
          },
          {
            key: "isActive",
            label: "Ad Status",
            compName: "dynamicTypeMetric",
            displayedData: [
              {
                value: "Active",
              },
              {
                value: "Inactive",
              },
            ],
          },
        ],
      },
      pagination: {},
      selectedAds: [],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.initialFiltersHandler();
    // this.filters = JSON.parse(sessionStorage.getItem("filter")).ads;
    this.getAds();
  },
  computed: {
    paginationCurrentPage() {
      let pageNumberToInt = parseInt(this.$route.query.page || 1);
      return pageNumberToInt;
    },
    ...mapGetters({
      lookups: "getAllLookups",
    }),
  },

  methods: {
    checkAdDate(val) {
      let givenDate = moment(val);
      let now = moment();
      console.log(givenDate.isBefore(now));
      // Check if the given date is before the current date and time
      return givenDate.isBefore(now);
    },
    getAds() {
      if (!this.filters.isActive) this.filters.isActive = "Active";
      this.$loading();
      MarktingService.ads
        .getads(this.paginationCurrentPage, this.filters)
        .then((res) => {
          this.adsList = res.ads;
          this.pagination = res.pagination;
        })
        .finally(() => {
          this.$loading().close();
        });
    },
    viewAd(ad) {
      this.$router.push({
        name: "editAdPage",
        params: {
          id: ad.id,
        },
        query: { type: ad.type, provider: ad.provider },
      });
    },
    deleteAd(ad) {
      this.$confirm(this.$t("adsPagePopupMessage"), "Warning", {
        confirmButtonText: this.$t("adsPagePopupOkButtonLabel"),
        cancelButtonText: this.$t("adsPagePopupCancelButtonLabel"),
        type: "warning",
      })
        .then(() => {
          MarktingService.ads.deletead(ad).then(() => {
            this.getAds();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("adsPagePopupErrorCatchMessage"),
          });
        });
    },
    createAd() {
      this.$router.push("/marketing/ad/add");
    },
    //** Filters
    initialFiltersHandler() {
      let filtersSession = sessionStorage.getItem("filter");
      if (filtersSession) {
        filtersSession = JSON.parse(filtersSession);
        if (filtersSession.ads) {
          const filtersObject = filtersSession.ads;
          if (filtersObject && filtersObject.filters)
            this.filters = { ...filtersObject.filters };
        }
      }
      let metricsSession = localStorage.getItem("metricsAds");
      if (metricsSession) {
        this.metricsList = JSON.parse(metricsSession);
      }
    },
    clearFilters() {
      this.filters = {};
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.ads = this.filters;
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.metricsList = [];
      localStorage.setItem("metricsAds", []);
      this.reloadPage();
    },
    saveMetircs() {
      localStorage.setItem("metricsAds", JSON.stringify(this.metricsList));
    },
    closeMetric(metric) {
      remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem("metricsAds", JSON.stringify(this.metricsList));
      this.metricsList = [...this.metricsList];
    },
    filterEnterClicked(key, value) {
      this.filtersChanged(key, value);
    },
    reloadPage() {
      if (this.$route.fullPath !== "/marketing/AdsPage?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
      }
      this.getAds();
    },
    filtersChanged(key, value) {
      if (!(typeof value === "number") && isEmpty(value)) {
        this.filters[key] = undefined;
      }
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.ads = {
        filters: { ...this.filters },
      };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.reloadPage();
    },
    // filtersChanged(key, value) {
    //   if (_.isEmpty(value)) {
    //     this.filters[key] = undefined;
    //   }
    //   // let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
    //   // sessionFilter.ads = { ...this.filters };
    //   // sessionStorage.setItem("filter", JSON.stringify(sessionFilter));

    //   if (this.$route.fullPath !== "/marketing/AdsPage?page=1") {
    //     this.$router.push({
    //       query: {
    //         page: 1,
    //       },
    //     });
    //   }
    //   this.getAds();
    // },
    handlePagination(val) {
      const pageLoading = this.$loading();
      MarktingService.ads
        .getads(val, this.filters)

        .then((response) => {
          this.adsList = response.ads;
          this.pagination = response.pagination;

          this.$router.push({
            query: {
              page: response.pagination.nextPage
                ? response.pagination.nextPage - 1
                : response.pagination.totalPages,
            },
          });
        })
        .finally(() => {
          pageLoading.close();
        });
    },
    setSelectedAds(Items) {
      this.selectedAds = Items;
    },
    async bulkDeleteAdsClicked() {
      let apiAdsIds = [];
      this.selectedAds.forEach((singleAd) => {
        apiAdsIds.push(singleAd.id);
      });
      const pageLoading = this.$loading();
      const bulkDeleteResponse = await MarktingService.ads.deleteAdsBulk(
        apiAdsIds
      );
      if (bulkDeleteResponse.data && bulkDeleteResponse.data.status == true) {
        pageLoading.close();
        this.getAds();
      } else {
        pageLoading.close();
        this.$message({
          message: this.$t("adsPagePopupErrorOccuredMessage"),
          type: "error",
        });
      }
      this.selectedAds = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.bulk-action-button-holder {
  position: absolute;
  top: 5vh;
  right: 5vw;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 250px;
  height: 150px;
  &__parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.operations-column-buttons-holder {
  display: flex;
}
[dir="rtl"] {
  .sections-page__float-button {
    float: left;
  }
}
::v-deep .enlargeable-image .full.enlarged {
  display: flex;
  position: fixed;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 75% !important;
  height: 80% !important;
  background-color: transparent;
  cursor: zoom-out;
  z-index: 2;
}
</style>
